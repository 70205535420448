
body {
  font-family: 'Chopin', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}
main {
  overflow: hidden;
}
h1 {
  text-align: center;
  margin-top: 20px;
}

h3 {
  margin-top: 20px;
}
