/* src/assets/fonts/fonts.css */

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-ExtraBoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-ExtraLightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-RegularItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Chopin';
    src: url('./Chopin-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}
